import React, { useState, useRef, useEffect } from 'react'
import ArrowDown from '../Icons/ArrowDown'
import ArrowUp from '../Icons/ArrowUp'
import Plus from '../Icons/Plus'
import Minus from '../Icons/Minus'
import Trash from '../Icons/Trash'
import '../../scss/locationRow.scss'
import useClickOutside from '../../hooks/useClickOutside'

function LocationRow({ item, objState, setState }) {
    const btnRef = useRef()

    const [menu, setMenu] = useState(false)
    const [selected, setSelected] = useState("No Location")
    const [quantity, setQuantity] = useState(0)
    const [location, setLocation] = useState(
        {
            id: 0,
            location: null,
            quantity: 0
        }
    )
    
    function handleLocationSelector() {
        setMenu(prevValue => !prevValue)
        addActiveClassToLocationSelector()
    }

    function addActiveClassToLocationSelector() {
        if(btnRef.current.classList.contains("open")) {
            btnRef.current.classList.remove("open")
        } else {
            btnRef.current.classList.add("open")
        }
    }

    function handleSelection(e) {
        setSelected(e.target.innerText)
        location['location'] = e.target.innerText
        handleLocationSelector()
    }
    
    function handleQuantityDecrease() {
        if(quantity !== 0) {
            setQuantity(prevCount => {
                return prevCount - 1
            })
            location['quantity'] = quantity
        }
    }
    
    function handleQuantityIncrease() {
        setQuantity(prevCount => {
            return prevCount + 1
        })
        location['quantity'] = quantity
    }

    function handleChange(e) {
        const result = Number(e.target.value)
        location['quantity'] = result
        setQuantity(result)
    }

    useEffect(() => {
        setLocation(item)
    }, [item])

    useEffect(() => {
        location['quantity'] = quantity
    }, [quantity, location])

    function removeItem() {
        const newList = objState.filter((location) => location.id !== item.id)
        setState(newList)
    }

    let domNode = useClickOutside(() => {
        setMenu(false)
        btnRef.current.classList.remove("open")
    })

    return (
        <div className="selection-wrapper">
            <div className="input-wrapper">
                <div className="location-input">
                    <h4 className="selection-heading">Location</h4>
                    <div ref={domNode}>
                        <button type="button" className="location-selector-btn" onClick={handleLocationSelector} ref={btnRef}>
                            {selected}
                            {menu ? <ArrowUp /> : <ArrowDown />}
                        </button>
                        {menu &&
                            <div className="expanded-menu">
                                <ul>
                                    <li className="li-selection" onClick={((e) => handleSelection(e))}>No Location</li>
                                    <li className="li-selection" onClick={((e) => handleSelection(e))}>Home</li>
                                    <li className="li-selection" onClick={((e) => handleSelection(e))}>Vehicle</li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="quantity-wrapper">
                    <h4 className="selection-heading">Quantity</h4>
                    <div className="quantity-input">
                        <button type="button" className="increase" onClick={handleQuantityDecrease}><Minus /></button>
                        <input type="number" className="quantity-input" value={quantity} onChange={handleChange} />
                        <button type="button" className="decrease" onClick={handleQuantityIncrease}><Plus /></button>
                    </div>
                </div>
            </div>
            {location.id > 0 &&
                <button type="button" className="delete-btn" onClick={removeItem}><Trash /></button>
            }
        </div>
    )
}

export default LocationRow