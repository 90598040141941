import React, { useEffect, useRef, useState } from "react"
import Navigation from '../components/AuthenticationNavigation'
import '../scss/login.scss'
import { useNavigate, Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import Loader from '../components/Loader'

function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()

  const { login } = useAuth()

  const [error, setError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [emailValid, setEmailValid] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)
  
  const [loading, setLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const navigate = useNavigate()

  function handleResize() {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [screenWidth])

  function validateEmail() {
    const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

    if(emailRef.current.value === null || emailRef.current.value === '') {
      setEmailError('Email Address is required')
      document.getElementById('email').classList.add('error-input-field')
    } else if(!re.test(emailRef.current.value)) {
      setEmailError('Email Address must be valid')
      document.getElementById('email').classList.add('error-input-field')
    } else {
      setEmailValid(true)
      setEmailError('')
      document.getElementById('email').classList.remove('error-input-field')
    }
  }

  function validatePassword() {
    if(passwordRef.current.value === null || passwordRef.current.value === '') {
      setPasswordError('Password is required')
      document.getElementById('password').classList.add('error-input-field')
    } else {
      setPasswordValid(true)
      setPasswordError('')
      document.getElementById('password').classList.remove('error-input-field')
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()


    if(emailValid && passwordValid) {
      try {
        setError('')
        setLoading(true)
        await login(emailRef.current.value, passwordRef.current.value)
        navigate('/dashboard')
      } catch {
        setError('The email or password you entered is incorrect.')
      }
      setLoading(false)
    }
  }

  return (
    <div className="login-section">
      {screenWidth > 1199 &&
        <Navigation cta='New to Part Docker?' link='/signup' linkText='Register' />        
      }
      <div className="login-form-section">
        {screenWidth < 1199 &&
        <>
          <div className="seaFloorPlantLeft"></div>
          <div className="seaFloorPlantRight"></div>
        </>
        }
        <div className="form-card">
          <h2 className="card-heading">Log in to your account</h2>
          {error && <div className="error-card">{error}</div>}
          <form className="authentication-form" onSubmit={handleSubmit}>
            <label className="form-input-label" htmlFor="email">email</label>
            <input className="form-input-field" type="text" id="email" placeholder="Enter your email address" ref={emailRef} onBlur={validateEmail} />
            {emailError && <p className="error-message">{emailError}</p>}
            <label className="form-input-label" htmlFor="password">password</label>
            <input className="form-input-field password-input-field" type="password" id="password" placeholder="Enter your password" ref={passwordRef} onBlur={validatePassword} />
            {passwordError && <p className="error-message">{passwordError}</p>}
            <button type="submit" className="submit-form-btn">Log In</button>
          </form>
          {loading && <div className="loading-message">
            <Loader /><p>Signing in</p>
          </div>}
          <Link to='/forgotPassword' className="forgot-password-link">Forgot Password?</Link>
        </div>
        {screenWidth < 1199 && <>
          <span class="register-cta">New to Part Docker? <Link to='/signup'>Register</Link></span>
        </>}
      </div>
      {screenWidth > 1199 &&
        <div className="login-hero-right">
          <h1 className="heading">Introducing Part Docker</h1>
          <h3 className="subheading"> Get the status info you need to stay on top of inventory. Gather data that will help you save & utilize resources to keep your customers happy.</h3>
        </div>
      }
    </div>
  )
}

export default Login