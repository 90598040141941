import React, { useRef } from 'react'

function InputField({ id, placeholder, setError, setValid, object, property, errorMessage }) {

    const inputRef = useRef()

    function validateField() {
        if (inputRef.current.value === null || inputRef.current.value === '') {
            setError(`${errorMessage}`)
            document.getElementById(`${id}`).classList.add('error-input-field')
        } else {
            setValid(true)
            setError('')
            document.getElementById(`${id}`).classList.remove('error-input-field')
            object[`${property}`] = inputRef.current.value
        }
    }

    return (
        <input className='form-input-field' ref={inputRef} onBlur={validateField} type="text" id={id} placeholder={placeholder} />
    )
}

export default InputField