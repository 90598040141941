import React, { useState } from 'react'
import { StyledAccountIcon } from '../styles/AccountIconStyled'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import useClickOutside from '../../hooks/useClickOutside'
import { motion, AnimatePresence } from 'framer-motion'

function AccountIcon() {
  const navigate = useNavigate()
  const { logout } = useAuth()

  const [cardVisible, setCardVisible] = useState(false)

  async function handleLogout() {
    try {
      await logout()
      navigate('/login')
    } catch {
      
    }
  }

  let domNode = useClickOutside(() => {
    setCardVisible(false)
  })

  const containerVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: { delay: .3 }
    }
  }

  return (
   <StyledAccountIcon ref={domNode}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setCardVisible((isOpen) => !isOpen)}>
        <mask id="path-1-inside-1_115_99" fill="white">
            <path d="M0 19C0 15.6863 2.68629 13 6 13H14C17.3137 13 20 15.6863 20 19V20H0V19Z"/>
        </mask>
        <path d="M-1 19C-1 15.134 2.13401 12 6 12H14C17.866 12 21 15.134 21 19H19C19 16.2386 16.7614 14 14 14H6C3.23858 14 1 16.2386 1 19H-1ZM20 20H0H20ZM-1 20V19C-1 15.134 2.13401 12 6 12V14C3.23858 14 1 16.2386 1 19V20H-1ZM14 12C17.866 12 21 15.134 21 19V20H19V19C19 16.2386 16.7614 14 14 14V12Z" fill="black" mask="url(#path-1-inside-1_115_99)"/>
        <circle cx="10" cy="5" r="4.5" stroke="black"/>
    </svg>
    <AnimatePresence>
    {cardVisible && 
        <motion.div
          className="account-card"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{
            opacity: { duration: 0.1 },
            layout: {
              type: 'spring',
              bounce: 0.4
            }
          }}
        >
            <Link to='/account'>My Account</Link>
            <button onClick={handleLogout}>Sign Out</button>
        </motion.div>
    }
    </AnimatePresence>
    </StyledAccountIcon>
  )
}

export default AccountIcon