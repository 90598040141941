import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/blueLogo2.svg'
import '../scss/authNavigation.scss'

function AuthenticationNavigation({ cta, link, linkText }) {
    return (
        <div className="auth-nav">
            <Link to='/'>
                <img className="brand" src={Logo} alt="Part Docker Logo" />
            </Link>
            <div className="auth-cta">
                {cta} <Link to={link}>{linkText}</Link>
            </div>
        </div>
    )
}

export default AuthenticationNavigation