import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import Loader from '../Loader'
import LongArrowLeft from '../Icons/LongArrowLeft'
import Input from './InputField'
import LocationRow from './LocationRow'
import '../../scss/createPart.scss'
import { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

function CreatePart({ onClick, handleToast }) {
    const { createPart, createLocationByPart } = useAuth()

    const [pNumberError, setPNumberError] = useState('')
    const [nameError, setNameError] = useState('')
    const [manufacturerError, setManufacturerError] = useState('')
    const [applianceTypeError, setApplianceTypeError] = useState('')
    const [error, setError] = useState('')

    const [pNumberValid, setPNumberValid] = useState(false)
    const [nameValid, setNameValid] = useState(false)
    const [manufacturerValid, setManufacturerValid] = useState(false)
    const [applianceTypeValid, setApplianceTypeValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [locationId, setLocationId] = useState(1)

    const [price, setPrice] = useState('')

    const [part, setPart] = useState({
        number: null,
        name: null,
        price: null,
        type: null,
        manufacturer: null
    })

    const [location, setLocation] = useState([
        {
            id: 0,
            location: 'No Location',
            quantity: 0
        }
    ])

    async function handleSubmit(e) {
        e.preventDefault()

        if (pNumberValid && nameValid && manufacturerValid && applianceTypeValid) {
            try {
                setLoading(true)
                await createPart(part)
                await createLocationByPart(location, part.number)
                onClick()
                const toastObj = {
                    id: Math.floor(Math.random() * 1000),
                    type: 'success',
                    message: 'Part created successfully.'
                }
                handleToast(toastObj)
            } catch(err) {
                if(err === `Part with Part# ${part.number} already exists`) {
                    setPNumberError(`Part with Part# ${part.number} already exists`)
                    document.getElementById('partNumber').classList.add('error-input-field')
                } else {
                    setError('An issue has occured. Please contact support if issue persists.')
                }
            }
            setLoading(false)
        } else {
            
        }
    }

    function addNewLocation() {
        const locationObj = {
            id: 0,
            location: 'No Location',
            quantity: 0
        }

        locationObj.id = locationId
        setLocationId(prevValue => prevValue + 1)

        setLocation([...location, locationObj])
    }

    const currencyMask = (e) => {
        let value = e.target.value
        value = value.replace(/\D/g, "")
        value = value.replace(/(\d)(\d{2})$/, "$1.$2")
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ",")
        e.target.value = value
        return e
    }

    const handleChange = (e) => {
        setPrice(e.target.value)
    }

    useEffect(() => {
        part['price'] = price
    }, [price, part])

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { delay: .3 }
        },
        exit: {
            x: '-100vw',
            transition: { ease: 'easeInOut' }
        }
    }

    return (
        <motion.div
            className='create-part-wrapper'
            variants={containerVariants}
            initial="hidden"  
            animate="visible"
            exit="exit"
        >
            <button className="link-text" onClick={onClick}><LongArrowLeft /> Back to Dashboard</button>
            <h2 className='create-part-heading'>Create a Part</h2>
                <form className='create-part-form' onSubmit={handleSubmit}>
                    <h3 className='section-heading'>Enter part data</h3>
                    <div className="grid-input-container">
                        <div className='input-group'>
                            <label className='form-input-label'>Part number <span className="required">*</span></label>
                            <Input id={'partNumber'} placeholder={'Enter a part number'} setError={setPNumberError} setValid={setPNumberValid} object={part} property={'number'} errorMessage={'*Part number is required'} />
                            {pNumberError && <div className='error-message'> {pNumberError}</div>}
                        </div>
                        <div className='input-group'>
                            <label className='form-input-label'>Name <span className="required">*</span></label>
                            <Input id={'partName'} placeholder={'Enter a part name'} setError={setNameError} setValid={setNameValid} object={part} property={'name'} errorMessage={'*Name is required'} />
                            {nameError && <div className='error-message'> {nameError}</div>}
                        </div>
                        <div className='input-group'>
                            <label className='form-input-label'>Manufacturer <span className="required">*</span></label>
                            <Input id={'manufacturer'} placeholder={'Enter the part manufacturer'} setError={setManufacturerError} setValid={setManufacturerValid} object={part} property={'manufacturer'} errorMessage={'*Manufacturer is required'} />
                            {manufacturerError && <div className='error-message'>{manufacturerError}</div>}
                        </div>
                        <div className='input-group'>
                            <label className='form-input-label'>Appliance type <span className="required">*</span></label>
                            <Input id={'partType'} placeholder={'Enter the appliance that this part fits'} setError={setApplianceTypeError} setValid={setApplianceTypeValid} object={part} property={'type'} errorMessage={'*Type is required'} />
                            {applianceTypeError && <div className='error-message'>{applianceTypeError}</div>}
                        </div>
                        <div className="input-group">
                            <label className='form-input-label'>Price <span className="required">*</span></label>
                            <div className="price-input-container">
                                <div className="input-currency-symbol">$</div>
                                <div className="price-input-placeholder">
                                    <input type="text" name="price" id="price" placeholder="0.00" value={price} onChange={(e) => handleChange(currencyMask(e))} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className='section-heading'>Enter part quantity</h3>
                    <div className="location-quantity-selector">
                        <div className="selector-wrapper">
                            {location.map((item) => {
                                return <AnimatePresence>
                                        <LocationRow key={item.id} item={item} objState={location} setState={setLocation} />
                                    </AnimatePresence>
                            })}
                        </div>
                        {location.length < 3 &&
                            <div className="btn-wrapper">
                                    <button type="button" className="add-location-btn" onClick={addNewLocation}>Add Another Location</button>
                            </div>
                        }
                    </div>
                    <div className="submit-section">
                        <button type="submit" className="create-part-btn">Create New Part</button>
                        {loading && <Loader />}
                    </div>
                    {error && <p className="error-message">{error}</p>}
            </form>
        </motion.div>
    )
}

export default CreatePart