import { useState, useRef, useEffect } from "react"
import { useAuth } from "../contexts/AuthContext"
import '../scss/myAccount.scss';
import Navigation from './SideNav'
import Notifications from './Notifications'
import Loader from './Loader'
import { motion } from 'framer-motion'

function MyAccount() {

    const newNameRef = useRef()
    const passwordRef = useRef()

    const [name, setName] = useState('')
    const [createdOn, setCreatedOn] = useState('')
    const [email, setEmail] = useState('')
    const [nameError, setNameError] = useState('')
    const [updateNameLoading, setUpdateNameLoading] = useState(false)
    const [nameValid, setNameValid] = useState(false)
    const [editingName, setEditingName] = useState(false)
    const [password, setPassword] = useState('•••••••••••••')
    const [toastList, setToastList] = useState([])

    const { currentUser, getUser, updateUser, resetPassword } = useAuth()

    const formattedDate = new Date(createdOn).toLocaleDateString('en-us')

    useEffect(() => {
        const userData = getUser(currentUser.uid).then((data) => {
            setName(data.name)
            setCreatedOn(data.dateCreated)
            setEmail(currentUser.email)
        })
    }, [currentUser.email, currentUser.uid, getUser, name])

    function validateName() {
        if (newNameRef.current.value === null || newNameRef.current.value === '') {
            setNameError('*Name is required')
            setNameValid(false)
        } else {
            setNameError('')
            setNameValid(true)
        }
    }

    function onCancel() {
        setEditingName(false)
        setNameError('')
    }

    function sendPasswordEmail() {
        resetPassword(email).then(() => {
            const toastObj = {
                id: Math.floor(Math.random() * 1000),
                type: 'success',
                message: 'Reset password email sent! Please check spam folder!'
            }
            handleToast(toastObj)
            setPassword('Email has been sent! Please check your spam folder')
            passwordRef.current.classList.add('warning')
            setTimeout(() => {
                passwordRef.current.classList.remove('warning')
                setPassword('•••••••••••••')
            }, 30000)
        })
    }

    function handleToast(toastObj) {
        setToastList([...toastList, toastObj])
    }

    async function handleNameSubmit(e) {
        e.preventDefault()
        if(nameValid) {
            setUpdateNameLoading(true)
            setNameError('')
            try {
                await updateUser(newNameRef.current.value)
                setName()
            } catch {
                setNameError('*Error updating name')
            }
            setUpdateNameLoading(false)
            setNameValid(false)
            setEditingName(false)
        }
    }

    const containerVariants = {
        hidden: {
          opacity: 0
        },
        visible: {
          opacity: 1,
          transition: { delay: .3 }
        }
      }

    return (
        <motion.div
            className="aurora-container"
            variants={containerVariants}
            initial="hidden"  
            animate="visible"
            exit="hidden"
        >
            <Navigation />
            <div className="aurora-container-right">
                <Notifications toastList={toastList} setToastList={setToastList} />
                <div className="account-wrapper">
                    <h1 className="account-heading">{name}</h1>
                    <h5 className="account-subheading">Member since {formattedDate}</h5>
                    <ul className="navbar">
                        <li className="list-item">
                            <button>Profile</button>
                        </li>
                    </ul>

                    <div className="settings-container">
                        <div className="settings-item">
                            <div className="setting-heading">
                                <h4>Name</h4>
                            </div>
                            <div className="setting-current">
                            {editingName ? (
                                <form className="form-update-user">
                                    <span className="update-heading">Edit name<span>*</span></span>
                                    <input className="form-input-field" type="text" id="name" ref={newNameRef} onBlur={validateName} placeholder="Please enter a new name" />
                                    {nameError && <span className="error-message">{nameError}</span>}
                                    <div className="btn-wrapper">
                                        <button type="submit" className="save-btn" onClick={handleNameSubmit}>Save</button>
                                        <button className="cancel-btn" onClick={onCancel}>Cancel</button>
                                    </div>
                                    {updateNameLoading && <div className="loading-message">
                                        <Loader /><p>Updating Name</p>
                                    </div>}
                                </form>) : (<span>{name}</span>)}
                            </div>
                            <div className="edit-btn">
                                <button onClick={() => setEditingName(true)}>
                                    {!editingName && <>Edit</>}
                                </button>
                            </div>
                        </div>
                        <div className="settings-item">
                            <div className="setting-heading">
                                <h4>Password</h4>
                            </div>
                            <div className="setting-current">
                                <span ref={passwordRef}>{password}</span>
                            </div>
                            <div className="edit-btn"><button onClick={sendPasswordEmail}>Reset Password</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}
export default MyAccount