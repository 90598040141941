import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import Loader from '../../components/Loader'

const PartRow = ({ refresh, auth, data, heading, placeholder, subheading, property, partNumber }) => {
    const fieldRef = useRef()

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    const [editingName, setEditingName] = useState(false)
    const [part, setPart] = useState({})

    useEffect(() => {
        setPart(data)
    }, [data])

    function validateField() {
        if (fieldRef.current.value === null || fieldRef.current.value === '') {
            setError(`*${heading} is required`)
            setValid(false)
        } else {
            setError('')
            setValid(true)
            part[`${property}`] = fieldRef.current.value
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if(valid) {
            setLoading(true)
            setError('')
            try {
                await auth(part, partNumber)
                setEditingName(false)
                refresh()
            } catch(err) {
                if(err === `Part with Part# ${part.number} already exists`) {
                    setError(`Part with Part# ${part.number} already exists`)
                } else {
                    setError('An issue has occured. Please contact support if issue persists.')
                    console.log(err)
                }
            }
            setLoading(false)
            setValid(false)
        }
    }

    return (
        <div className="settings-item">
            <div className="setting-heading">
                <h4>{heading}</h4>
            </div>
            <div className="setting-current">
                {editingName ? (
                    <form className="form-update-user">
                        <span className="update-heading">Edit {heading}<span>*</span></span>
                        <input className="form-input-field" type="text" id="name" ref={fieldRef} onChange={validateField} placeholder={placeholder} />
                        {error && <span className="error-message">{error}</span>}
                        <div className="btn-wrapper">
                            <button type="submit" className="save-btn" onClick={handleSubmit}>Save</button>
                            <button className="cancel-btn" onClick={() => {
                                setEditingName(false)
                                setError('')
                            }}>Cancel</button>
                        </div>
                        {loading && <div className="loading-message">
                            <Loader /><p>Updating</p>
                        </div>}
                    </form>) : (<span>{subheading}</span>)}
            </div>
            <div className="edit-btn">
                <button onClick={() => setEditingName(true)}>
                    {!editingName && <>Edit</>}
                </button>
            </div>
        </div>
    )
}

export default PartRow