import React, { useState } from 'react'
import Table from './Table'
import EditLocation from './EditLocation'
import SaveModal from './SaveModal'
import AddLocationModal from './AddLocationModal'
import { AnimatePresence } from 'framer-motion'

const LocationRow = ({ heading, auth, refresh, inventory, partNum }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [newLocationModal, setNewLocationModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [error, setError] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()
        setModalOpen(true)
    }

    return (
        <div className="settings-item">
            <AnimatePresence initial={false} exitBeforeEnter={true}>
                {modalOpen && <SaveModal refresh={refresh} setEditing={setEditing} partNum={partNum} text="Are you sure you want to make these changes?" data={inventory} auth={auth} handleClose={() => setModalOpen(false)} />}
            </AnimatePresence>
            <AnimatePresence initial={false} exitBeforeEnter={true}>
                {newLocationModal && <AddLocationModal refresh={refresh} partNum={partNum} setEditing={setEditing} text="Add New Location" handleClose={() => setNewLocationModal(false)} />}
            </AnimatePresence>
            <div className="setting-heading">
                <h4>{heading}</h4>
            </div>
            <div className="setting-current">
                {editing ? (
                    <div className="form-update-user">
                        <span className="update-heading">Edit {heading}<span>*</span></span>
                        <div className="edit-location-row-wrapper">
                            {inventory.map((item) => {
                                return <EditLocation key={item.inventory_location} refresh={refresh} setEditing={setEditing} partNum={partNum} data={item} />
                            })}
                        </div>
                        <span className="add-new__btn" onClick={() => setNewLocationModal(true)}>+ Add New Location</span>
                        {/* <input className="form-input-field" type="text" id="name" ref={fieldRef} onChange={validateField} placeholder={placeholder} /> */}
                        {error && <span className="error-message">{error}</span>}
                        <div className="btn-wrapper">
                            <button className="save-btn" onClick={handleSubmit}>Save</button>
                            <button className="cancel-btn" onClick={() => {
                                setEditing(false)
                                setError('')
                            }}>Cancel</button>
                        </div>
                    </div>) : <div className="location-list">
                        <div>
                            <Table data={inventory} />
                        </div>
                    </div>}
            </div>
            <div className="edit-btn">
                <button onClick={() => setEditing(true)}>
                    {!editing && <>Edit</>}
                </button>
            </div>
        </div>
    )
}

export default LocationRow