import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getFirestore} from 'firebase/firestore'

const app = firebase.initializeApp({
    apiKey: "AIzaSyCfAVFDiLVqYWeKj6BNg2y5IcyFZN6TstQ",
    authDomain: "partdocker-1a248.firebaseapp.com",
    projectId: "partdocker-1a248",
    storageBucket: "partdocker-1a248.appspot.com",
    messagingSenderId: "374659815225",
    appId: "1:374659815225:web:5e982d18eb449855d4fae8"
})

export const auth = app.auth()
export default app
export const db = getFirestore(app)
