import React, { useState } from 'react'
import '../scss/dashboard.scss'
import Navigation from './SideNav'
import CreatePart from './CreatePart/CreatePart'
import InventoryView from './InventoryView'
import Notifications from './Notifications'
import { motion, AnimatePresence } from 'framer-motion'
import UpdatePart from './UpdatePart/UpdatePart'

function Dashboard() {
  const [displayCreatePart, setDisplayCreatePart] = useState(false)
  const [displayPartsTable, setDisplayPartsTable] = useState(true)
  const [displayUpdatePart, setDisplayUpdatePart] = useState(false)
  const [toastList, setToastList] = useState([])
  const [partData, setPartData] = useState({})

  const currentDate = Date()
  const formattedMonth = new Date(currentDate).toLocaleDateString('en-us', {
    weekday: 'long',
    month: 'short',
    day: 'numeric'
  })

  function handleCreatePartNavigation() {
    setDisplayCreatePart(prevValue => !prevValue)
    setDisplayPartsTable(prevValue => !prevValue)
  }

  function handleUpdatePartNavigation(){
    setDisplayPartsTable(prevValue => !prevValue)
    setDisplayUpdatePart(prevValue => !prevValue)
  }

  const displayToast = (toastObj) => {
    setToastList([...toastList, toastObj])
  }

  const containerVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: { delay: .3 }
    }
  }

  return (
    <motion.div
      className="aurora-container"
      variants={containerVariants}
      initial="hidden"  
      animate="visible"
      exit="hidden"
    >
      <Navigation />
      <div className="aurora-container-right">
        <div className="dashboard-heading">
          <h1>Dashboard</h1>
          <span className="date">{formattedMonth}</span>
        </div>
        {displayPartsTable && <InventoryView onClickCreate={handleCreatePartNavigation} onClickRow={handleUpdatePartNavigation} setPartData={setPartData} />}
        {displayCreatePart && <CreatePart onClick={handleCreatePartNavigation} handleToast={displayToast} />}
        {displayUpdatePart && <UpdatePart partData={partData} onClick={handleUpdatePartNavigation} />}
        <Notifications toastList={toastList} setToastList={setToastList} />
      </div>
    </motion.div>
  )
}

export default Dashboard