import React, { useState, useCallback, useEffect } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import LongArrowLeft from '../Icons/LongArrowLeft'
import '../../scss/myAccount.scss'
import '../../scss/updatePart.scss'
import TableRow from './PartRow'
import PriceRow from './PartRowPrice'
import LocationRow from './LocationRow'
import DeletePart from './DeletePart'

function UpdatePart({ onClick, partData }) {
    const [part, setPart] = useState({})
    const [inventory, setInventory] = useState([])

    const { updatePart, getPart, updatePartInventory, deletePart } = useAuth()

    const getPartCallback = useCallback(() => {
        return getPart(partData.number).then((item) => {
            setPart(item)
            setInventory(item.inventory)
        })
    }, [partData, getPart])

    useEffect(() => {
        getPartCallback()
    }, [partData, getPartCallback])

    return (
        <div className="update-part-wrapper">
            <button className="link-text" onClick={onClick}><LongArrowLeft /> Back to Dashboard</button>
            <h2 className='create-part-heading'>Update Part</h2>
            <div className="account-wrapper">
                <ul className="navbar">
                    <li className="list-item">
                        <button>Profile</button>
                    </li>
                </ul>

                <div className="settings-container">
                    <TableRow auth={updatePart} refresh={getPartCallback} heading="Part#" partNumber={part.number} data={partData} subheading={part.number} property="number" placeholder="Please enter a new part#" />
                    <TableRow auth={updatePart} refresh={getPartCallback} heading="Part Name" partNumber={part.number} data={partData} subheading={part.name} property="name" placeholder="Please enter a new name" />
                    <TableRow auth={updatePart} refresh={getPartCallback} heading="Type" partNumber={part.number} data={partData} subheading={part.type} property="type" placeholder="Please enter a new type" />
                    <TableRow auth={updatePart} refresh={getPartCallback} heading="Manufacturer" partNumber={part.number} data={partData} subheading={part.manufacturer} property="manufacturer" placeholder="Please enter a new manufacturer" />
                    <PriceRow auth={updatePart} refresh={getPartCallback} heading="Price" partNumber={part.number} data={partData} subheading={part.price} property="price" />
                    <LocationRow heading="Your Inventory" refresh={getPartCallback} auth={updatePartInventory} inventory={inventory} partNum={part.number} />
                    <DeletePart auth={deletePart} onClick={onClick} partNum={part.number} />
                </div>
            </div>
        </div>
    )
}

export default UpdatePart