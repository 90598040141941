import React, { useEffect, useState, useMemo } from 'react'
import { useAuth } from '../contexts/AuthContext'
import Loader from './Loader'
import TableRow from './TableRow'
import '../scss/inventoryview.scss'
import Pagination from './Pagination'
import SearchPart from "./SearchPart"
import { motion } from 'framer-motion'

function InventoryView({ onClickCreate, onClickRow, setPartData }) {
  const [loading, setLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const { getParts } = useAuth()

  const [parts, setParts] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [filteredParts, setFilteredParts] = useState([])

  const pageHandler = (pageNumber) => {
    setFilteredParts(parts.slice(pageNumber * 10 - 10, pageNumber * 10))
  }

  const partCount = useMemo(() => {
    let count = 0
    parts.forEach((part) => {
      part.inventory.forEach((item) => {
        count = count + Number(item.inventory_quantity)
      })
    })
    return count
  }, [parts])

  useEffect(() => {
    getParts().then((data) => {
      setLoading(true)
      setParts(data)
      setFilteredParts(data.slice(0, 9))
    })

    setLoading(false)
  }, [getParts])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })
  }, [screenWidth])

  useEffect(() => {
    let filteredResult = []

    if(searchInput === '') {
      setFilteredParts(parts.slice(0, 9))
    } else {
      parts.filter((part) => {
        if (part.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          part.number.toLowerCase().includes(searchInput.toLowerCase()) ||
          part.manufacturer.toLowerCase().includes(searchInput.toLowerCase()) ||
          part.type.toLowerCase().includes(searchInput.toLowerCase())) {
            filteredResult.push(part)
        }
      })

      setFilteredParts(filteredResult)
    }
    }, [searchInput])

  const containerVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: { delay: .3 }
    },
    exit: {
      x: '-100vw',
      transition: { ease: 'easeInOut' }
    }
  }

  return (
    <motion.div
      className="inventory-view-container"
      variants={containerVariants}
      initial="hidden"  
      animate="visible"
      exit="exit"
    >
      <div className="row-wrapper">
        <h3 className="parts-available">{partCount} Parts</h3>
        {screenWidth < 768 && <button className="create-part-btn" onClick={onClickCreate}>Create</button>}
      </div>
      <div className="filter-query-container">
        <SearchPart setSearchInput={setSearchInput} searchInput={searchInput} />
        {screenWidth > 768 && <button className="create-part-btn" onClick={onClickCreate}>Create</button>}
      </div>
      <div className="table-container">
        <table className="parts-table">
          <thead className="table-heading">
            <tr>
              {screenWidth > 768 && <th className="th align-left">Part#</th>}
              <th className="th">Name</th>
              {screenWidth > 768 && <th className="th">Price</th>}
              {screenWidth > 1199 && <th className="th">Type</th>}
              {screenWidth > 768 && <th className="th">Manufacturer</th>}
              {screenWidth > 1199 && <th className="th">Location</th>}
              <th className="th mobile-align-right">Quantity</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {filteredParts.map((part) => {
              return <TableRow setPartData={setPartData} onClickRow={onClickRow} key={part.number} part={part} screenWidth={screenWidth} />
            })}
          </tbody>
        </table>
        {(filteredParts.length === 0 && loading) && <div className="no-parts-msg">No Parts Found</div>}
        {!loading && (
          <div className="loading-parts-text">
            Loading parts <Loader />
          </div>
        )}
        {parts.length > 0 && <Pagination data={parts} pageHandler={pageHandler} />}
      </div>
    </motion.div>
  )
}

export default InventoryView