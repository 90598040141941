import React from 'react'
import Backdrop from './Backdrop'
import { motion } from 'framer-motion'
import '../../scss/modal.scss'
import Table from './Table'

const DeleteModal = ({ handleClose, text, auth, data, partNum, setEditing, refresh }) => {
    const dropIn = {
        hidden: {
            y: '-100vh',
            opacity: 0
        },
        visible: {
            y: '0',
            opacity: 1,
            transition: {
                duration: 0.1,
                type: 'spring',
                damping: 25,
                stiffness: 250
            }
        },
        exit: {
            y: '100vh',
            opacity: 0
        }
    }

    function handleCancel(e) {
        e.preventDefault()
        handleClose()
    }

    async function handleRemove(e) {
        e.preventDefault()
        try {
            auth(data[0].inventory_location, partNum)
            handleClose()
            setEditing(false)
            refresh()
        } catch(err) {
            console.log(err)
        }
    }

    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <p>{text}</p>
                <Table data={data} />
                <div className="btns">
                    <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
                    <button className="delete-btn" onClick={handleRemove}>Remove</button>
                </div>
            </motion.div>
        </Backdrop>
    )
}

export default DeleteModal