import React, { useState, useEffect } from 'react'
import Plus from '../Icons/Plus'
import Minus from '../Icons/Minus'
import Trash from '../Icons/Trash'
import DeleteModal from './DeleteModal'
import { AnimatePresence } from 'framer-motion'
import { useAuth } from "../../contexts/AuthContext"

const EditLocation = ({ data, partNum, setEditing, refresh }) => {
    const [quantity, setQuantity] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)

    const { deleteLocation } = useAuth()

    function handleQuantityDecrease() {
        if(quantity !== 0) {
            setQuantity(prevCount => {
                return prevCount - 1
            })
        }
    }
    
    function handleQuantityIncrease() {
        setQuantity(prevCount => {
            return prevCount + 1
        })
    }

    function handleChange(e) {
        const result = Number(e.target.value)
        setQuantity(result)
    }

    function removeItem() {
        setModalOpen(true)
    }

    useEffect(() => {
        setQuantity(data.inventory_quantity)
    }, [data])

    useEffect(() => {
        if(quantity !== data.inventory_quantity) {
            data['new_inventory_quantity'] = quantity
        } else {
            delete data['new_inventory_quantity']
        }
    }, [quantity])

    return (
        <div className="selection-wrapper">
            <AnimatePresence initial={false} exitBeforeEnter={true}>
                {modalOpen && <DeleteModal setEditing={setEditing} refresh={refresh} data={[data]} text="Are you sure you want to remove this from your inventory?" auth={deleteLocation} partNum={partNum} handleClose={() => setModalOpen(false)} />}
            </AnimatePresence>
            <div className="input-wrapper">
                <div className="location-input">
                    <h4 className="selection-heading">Location</h4>
                    <div>
                        <div className="location-selector-btn">{data.inventory_location}</div>
                    </div>
                </div>
                <div className="quantity-wrapper">
                    <h4 className="selection-heading">Quantity</h4>
                    <div className="quantity-input">
                        <button type="button" className="increase" onClick={handleQuantityDecrease}><Minus /></button>
                        <input type="number" className="quantity-input" value={quantity} onChange={handleChange} />
                        <button type="button" className="decrease" onClick={handleQuantityIncrease}><Plus /></button>
                    </div>
                </div>
            </div>
            <button type="button" className="delete-btn" onClick={removeItem}><Trash /></button>
        </div>
    )
}

export default EditLocation