import React, { useRef, useState, useEffect } from "react"
import "../scss/pagination.scss"
import Arrow from '../components/Icons/ArrowUp'

const Pagination = ({ data, pageHandler }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [userInput, setUserInput] = useState(1)
  const inputRef = useRef(null)
  const maxPages = Math.ceil(data.length / 10)

  function previousPage() {
    let previousValue = currentPage

    let newValue = Number(currentPage) - Number(1)
    if (newValue < maxPages && newValue >= 1) {
      setCurrentPage(newValue)
      pageHandler(newValue)
    } else {
      setCurrentPage(previousValue)
    }
  }

  function nextPage() {
    let previousValue = currentPage;
    let newValue = Number(currentPage) + Number(1)

    if (newValue <= maxPages) {
      setCurrentPage(newValue)
      pageHandler(newValue)
    } else {
      setCurrentPage(previousValue)
    }
  }

  function submitForm(e) {
    e.preventDefault()

    if(userInput <= maxPages && userInput >= 1) {
      setCurrentPage(userInput)
      pageHandler(userInput)
    }
  }

  useEffect(() => {
    setUserInput(currentPage)
  }, [currentPage])

  return (
    <div className="pagination-container">
      <div className="pagination-wrapper">
        <>{/* eslint-disable-next-line */}</>
        <button className="paginate-btn left" disabled={currentPage == 1 || currentPage == 0} onClick={previousPage}>
          <Arrow />
        </button>
        <span className="form-text">Page</span>
        <form onSubmit={submitForm}>
          <input ref={inputRef} type="number" className="user-page-input" value={userInput} onChange={() => setUserInput(inputRef.current.value)} />
        </form>
        <span className="form-text">of &nbsp;&nbsp;{maxPages}</span>
        <>{/* eslint-disable-next-line */}</>
        <button className="paginate-btn right" disabled={currentPage == maxPages} onClick={nextPage}>
          <Arrow />
        </button>
      </div>
    </div>
  )
}

export default Pagination
