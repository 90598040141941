import React, { useCallback } from 'react'
import Toast from './Toast'
import '../scss/notifications.scss'
import { useEffect } from 'react'

function Notifications({ toastList, setToastList }) {

    const deleteToast = useCallback(id => {
        const toastItems = toastList.filter(e => e.id !== id)
        setToastList(toastItems)
    }, [toastList, setToastList])

    useEffect(() => {
        const interval = setInterval(() => {
            if(toastList.length) {
                deleteToast(toastList[0].id)
            }
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [toastList, deleteToast])

    return (
        <ul className="notifications-list">
            {toastList.map((toast, i) => (
                <Toast key={i} toast={toast} deleteToast={deleteToast} />
            ))}
        </ul>
    )
}

export default Notifications