import './App.css'
import './scss/index.scss'
import { Routes, Route, useLocation } from 'react-router-dom'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Home from './pages/Home'
import Dashboard from './components/Dashboard'
import MyAccount from './components/MyAccount'
import { AuthProvider } from './contexts/AuthContext'
import ProtectedRoute from './components/ProtectedRoute'
import ForgotPassword from './pages/ForgotPassword'
import { AnimatePresence } from 'framer-motion'

function App() {
  const location = useLocation()

  return (
    <AuthProvider>
      <AnimatePresence>
        <Routes location={location} key={location.key}>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/account" element={<ProtectedRoute><MyAccount /></ProtectedRoute>} />
        </Routes>
      </AnimatePresence>
    </AuthProvider>
  )
}

export default App
