import React from 'react'
import { StyledHamburger } from '../styles/Hamburger.styled'

function Hamburger({ onClick }) {

  return (
    <StyledHamburger onClick={onClick}>
        <div className="long-line"></div>
        <div className="short-line"></div>
        <div className="long-line"></div>
    </StyledHamburger>
  )
}

export default Hamburger