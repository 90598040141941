import styled from 'styled-components'

export const StyledHamburger = styled.button`
    width: 20px;
    height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: none;

    .long-line, .short-line {
        background-color: #000;
    }

    .long-line {
        height: 2px;
        width: 20px;
    }

    .short-line {
        height: 2px;
        width: 14px;
    }
`