import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import Loader from '../../components/Loader'

const PartRowPrice = ({ refresh, auth, data, heading, subheading, property, partNumber }) => {

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [editingName, setEditingName] = useState(false)
    const [part, setPart] = useState({})
    const [price, setPrice] = useState('')

    useEffect(() => {
        setPart(data)
    }, [data])

    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        setError('')
        try {
            await auth(part, partNumber)
            setEditingName(false)
            refresh()
        } catch(err) {
            if(err === `Part with Part# ${part.number} already exists`) {
                setError(`Part with Part# ${part.number} already exists`)
            } else {
                setError('An issue has occured. Please contact support if issue persists.')
                console.log(err)
            }
        }
        setLoading(false)
    }

    const currencyMask = (e) => {
        let value = e.target.value
        value = value.replace(/\D/g, "")
        value = value.replace(/(\d)(\d{2})$/, "$1.$2")
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ",")
        e.target.value = value
        return e
    }

    const handleChange = (e) => {
        setPrice(e.target.value)
    }
    
    useEffect(() => {
        part[`${property}`] = price
    }, [price, part, property])

    return (
        <div className="settings-item">
            <div className="setting-heading">
                <h4>{heading}</h4>
            </div>
            <div className="setting-current">
                {editingName ? (
                    <form className="form-update-user">
                        <span className="update-heading">Edit {heading}<span>*</span></span>
                        <div className="input-group">
                            <div className="price-input-container">
                                <div className="input-currency-symbol">$</div>
                                <div className="price-input-placeholder">
                                    <input type="text" name="price" id="price" placeholder="0.00" value={price} onChange={(e) => handleChange(currencyMask(e))} />
                                </div>
                            </div>
                        </div>
                        {error && <span className="error-message">{error}</span>}
                        <div className="btn-wrapper">
                            <button type="submit" className="save-btn" onClick={handleSubmit}>Save</button>
                            <button className="cancel-btn" onClick={() => {
                                setEditingName(false)
                                setError('')
                            }}>Cancel</button>
                        </div>
                        {loading && <div className="loading-message">
                            <Loader /><p>Updating</p>
                        </div>}
                    </form>) : (<span className="currency">${subheading}</span>)}
            </div>
            <div className="edit-btn">
                <button onClick={() => setEditingName(true)}>
                    {!editingName && <>Edit</>}
                </button>
            </div>
        </div>
    )
}

export default PartRowPrice