import React, { useState } from 'react'
import DeletePartModal from './DeletePartModal'

const DeletePart = ({ auth, partNum, onClick }) => {
    const [modalOpen, setModalOpen] = useState(false)

    async function handleDelete() {
        try {
            await auth(partNum)
            setModalOpen(false)
            onClick()
        } catch(err) {
            console.log(err) 
        }
    }

    return (
        <div className="settings-item">
            {modalOpen && <DeletePartModal partNum={partNum} auth={handleDelete} text={`Are you sure you want to delete part ${partNum}`} handleClose={() => setModalOpen(false)} />}
            <div className="setting-heading">
                <h4>Delete Part</h4>
            </div>
            <div className="setting-current">
                <span>Delete part and associated components</span>
            </div>
            <div className="edit-btn">
                <button className="delete-btn" onClick={() => setModalOpen(true)}>Delete</button>
            </div>
        </div>
    )
}

export default DeletePart