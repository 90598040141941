import React from 'react'
import { Link } from 'react-router-dom'
import '../scss/home.scss'
import { motion } from 'framer-motion'

function Home() {
  return (
    <div className="home-section">
      <div className="patternContainer"></div>
      <div className="content-wrapper">
        <motion.h1
          className="heading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: .2 }}
        >
          Introducing Part Docker
        </motion.h1>
        <motion.h2
          className="subheading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: .4 }}
        >
          Keep track of your ship.
        </motion.h2>
        <motion.div
          className="cta-btns"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: .8, type: 'spring', stiffness: 100 }}
        >
          <Link className="login-cta" to="/Login">Log In</Link>
          <Link className="signup-cta" to="/Signup">Signup</Link>
        </motion.div>
      </div>
    </div>
  )
}

export default Home