import React, { useState, useRef } from 'react'
import Backdrop from './Backdrop'
import ArrowDown from '../Icons/ArrowDown'
import { useAuth } from "../../contexts/AuthContext"
import ArrowUp from '../Icons/ArrowUp'
import Plus from '../Icons/Plus'
import Minus from '../Icons/Minus'
import { motion } from 'framer-motion'
import '../../scss/modal.scss'
import useClickOutside from '../../hooks/useClickOutside'
import { useEffect } from 'react'

const AddLocationModal = ({ handleClose, text, partNum, setEditing, refresh }) => {
    const { createLocationByPart } = useAuth()

    const [menu, setMenu] = useState(false)
    const [selected, setSelected] = useState("No Location")
    const [quantity, setQuantity] = useState(0)
    const [location] = useState(
        {
            location: 'No Location',
            quantity: 0
        }
    )

    const btnRef = useRef()

    const dropIn = {
        hidden: {
            y: '-100vh',
            opacity: 0
        },
        visible: {
            y: '0',
            opacity: 1,
            transition: {
                duration: 0.1,
                type: 'spring',
                damping: 25,
                stiffness: 250
            }
        },
        exit: {
            y: '100vh',
            opacity: 0
        }
    }

    function handleCancel(e) {
        e.preventDefault()
        handleClose()
    }

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            createLocationByPart([location], partNum)
            handleClose()
            setEditing(false)
            refresh()
        } catch(err) {
            console.log(err)
        }
    }

    function handleLocationSelector() {
        setMenu(prevValue => !prevValue)
        addActiveClassToLocationSelector()
    }

    function addActiveClassToLocationSelector() {
        if(btnRef.current.classList.contains("open")) {
            btnRef.current.classList.remove("open")
        } else {
            btnRef.current.classList.add("open")
        }
    }

    function handleSelection(e) {
        setSelected(e.target.innerText)
        location['location'] = e.target.innerText
        handleLocationSelector()
    }
    
    function handleQuantityDecrease() {
        if(quantity !== 0) {
            setQuantity(prevCount => {
                return prevCount - 1
            })
        }
    }
    
    function handleQuantityIncrease() {
        setQuantity(prevCount => {
            return prevCount + 1
        })
    }

    function handleChange(e) {
        const result = Number(e.target.value)
        setQuantity(result)
    }

    let domNode = useClickOutside(() => {
        setMenu(false)
        btnRef.current.classList.remove("open")
    })

    useEffect(() => {
        location['quantity'] = quantity
    }, [quantity])

    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal add-location-modal"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <p>{text}</p>
                <div className="selection-wrapper">
                    <div className="input-wrapper">
                        <div className="location-input">
                            <h4 className="selection-heading">Location</h4>
                            <div ref={domNode}>
                                <button type="button" className="location-selector-btn" onClick={handleLocationSelector} ref={btnRef}>
                                    {selected}
                                    {menu ? <ArrowUp /> : <ArrowDown />}
                                </button>
                                {menu &&
                                    <div className="expanded-menu">
                                        <ul>
                                            <li className="li-selection" onClick={((e) => handleSelection(e))}>No Location</li>
                                            <li className="li-selection" onClick={((e) => handleSelection(e))}>Home</li>
                                            <li className="li-selection" onClick={((e) => handleSelection(e))}>Vehicle</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="quantity-wrapper">
                            <h4 className="selection-heading">Quantity</h4>
                            <div className="quantity-input">
                                <button type="button" className="increase" onClick={handleQuantityDecrease}><Minus /></button>
                                <input type="number" className="quantity-input" value={quantity} onChange={handleChange} />
                                <button type="button" className="decrease" onClick={handleQuantityIncrease}><Plus /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <button className="cancel-btn" onClick={(handleCancel)}>Cancel</button>
                    <button className="save-btn" onClick={handleSubmit}>Save</button>
                </div>
            </motion.div>
        </Backdrop>
    )
}

export default AddLocationModal