import React from "react"
import "../scss/searchPart.scss";

function SearchPart({setSearchInput, searchInput}) {

    const handleSearch = (e) => {
        e.preventDefault()
        setSearchInput(e.target.value)
    }

    return (
        <input className="form-input-field" type="text" placeholder="Search for a part" onChange={handleSearch} value={searchInput} />
    )
}
export default SearchPart