import React, { useState } from 'react'
import Backdrop from './Backdrop'
import { motion } from 'framer-motion'
import '../../scss/modal.scss'
import { useEffect } from 'react'

const SaveModal = ({ handleClose, text, auth, data, partNum, setEditing, refresh }) => {
    const [inventory, setInventory] = useState([])
    const dropIn = {
        hidden: {
            y: '-100vh',
            opacity: 0
        },
        visible: {
            y: '0',
            opacity: 1,
            transition: {
                duration: 0.1,
                type: 'spring',
                damping: 25,
                stiffness: 250
            }
        },
        exit: {
            y: '100vh',
            opacity: 0
        }
    }

    function handleCancel(e) {
        e.preventDefault()
        handleClose()
    }

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            await auth(inventory, partNum)
            handleClose()
            setEditing(false)
            refresh()
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        setInventory(data)
    }, [data])

    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <p>{text}</p>
                <table className="parts-table">
            <thead className="table-heading">
            <tr>
                <th className="th">Location</th>
                <th className="th mobile-align-right">Quantity</th>
            </tr>
            </thead>
            <tbody className="table-body">
                {inventory.map((item) => {
                    return <tr key={item.inventory_location}>
                        <td>{item.inventory_location}</td>
                        {item.new_inventory_quantity ?
                            <td><span className="old-qty">{item.inventory_quantity}</span><span className="new-qty">{item.new_inventory_quantity}</span></td>
                            : 
                            <td><span>{item.inventory_quantity}</span></td>
                        }
                    </tr>
                })}
            </tbody>
        </table>
                <div className="btns">
                    <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
                    <button className="save-btn" onClick={handleSubmit}>Save</button>
                </div>
            </motion.div>
        </Backdrop>
    )
}

export default SaveModal