import React, { useState, useRef } from "react";
import Navigation from "../components/AuthenticationNavigation";
import { useAuth } from '../contexts/AuthContext'
import Loader from "../components/Loader";
import "../scss/forgotPassword.scss";
import { useEffect } from "react";

function ForgotPassword() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [cardDescription, setCardDescription] = useState('Enter the email address associated with your account and we will send you a link to reset your password')
  const [emailSent, setEmailSent] = useState(false)

  const emailRef = useRef()
  const descRef = useRef()

  const { resetPassword } = useAuth()

  function handleResize() {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [screenWidth])

  function validateEmail() {
    const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

    if (emailRef.current.value === null || emailRef.current.value === "") {
      setError("Email Address is required")
      document.getElementById("email").classList.add("error-input-field")
    } else if (!re.test(emailRef.current.value)) {
      setError("Email Address must be valid")
      document.getElementById("email").classList.add("error-input-field")
    } else {
      setEmailValid(true)
      setError("")
      document.getElementById("email").classList.remove("error-input-field")
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (emailValid) {
      try {
        setError("")
        setLoading(true)
        resetPassword(emailRef.current.value)
        setEmailSent(true)
        setCardDescription('We have emailed you a link to reset your password! If you did not receive an email, please check your spam folder.')
        descRef.current.classList.remove('card-description')
        descRef.current.classList.add('email-sent-msg')
      } catch {
        setError("An error occured sending an email to reset your password")
      }
      setLoading(false)
    }
  }
  return (
    <div className="forgot-password">
      {screenWidth > 768 && (
        <Navigation cta="Joined us before?" link="/login" linkText="Login" />
      )}
      <div className="form-card">
        <h2 className="card-heading">Forgot Password?</h2>
        <div ref={descRef} className="card-description">{cardDescription}</div>
        {!emailSent && <>
          <form className="authentication-form" onSubmit={handleSubmit}>
            <label htmlFor="email" className="form-input-label">email address</label>
            <input type="text" id="email" placeholder="Enter your email address" className="form-input-field" ref={emailRef} onBlur={validateEmail} />
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="submit-form-btn">Request Password Reset</button>
            {loading && (
            <div className="loading-message">
              <Loader />
              <p>Emailing your link</p>
            </div>
          )}
          </form>
        </>}
      </div>
    </div>
  );
}

export default ForgotPassword;
