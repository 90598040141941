import React, { useState, useEffect } from 'react'
import '../../scss/table.scss'

const Table = ({ data }) => {
    const [inventory, setInventory] = useState([])

    useEffect(() => {
        setInventory(data)
    }, [data])

    return (
        <table className="parts-table">
            <thead className="table-heading">
            <tr>
                <th className="th">Location</th>
                <th className="th mobile-align-right">Quantity</th>
            </tr>
            </thead>
            <tbody className="table-body">
                {inventory.map((item) => {
                    return <tr key={item.inventory_location}>
                        <td>{item.inventory_location}</td>
                        <td>{item.inventory_quantity}</td>
                    </tr>
                })}
            </tbody>
        </table>
    )
}

export default Table