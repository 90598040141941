import React, { useContext, useState, useEffect } from 'react'
import { auth, db } from '../backend/firebase'
import { collection, addDoc, doc, getDoc, where, getDocs, setDoc, query, updateDoc, deleteDoc } from 'firebase/firestore'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    async function signup(email, password, user) {
        return auth.createUserWithEmailAndPassword(email, password).then((cred) => {
            setDoc(doc(db, 'users', cred.user.uid), {
                name: user.name,
                dateCreated: Date()
            })
        })

  }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    async function getUser(uid) {
        const docSnap = await getDoc(doc(db, 'users', uid))
        if(docSnap.exists()) {
            return docSnap.data()
        }
    }

    async function updateUser(newName) {
        const docRef = doc(db, 'users', currentUser.uid)
        await updateDoc(docRef, {
            name: newName,
            dateUpdated: Date()
        })
    }

    async function updatePart(part, partNumber) {
        const docRef = doc(db, 'users', currentUser.uid)
        const objectRef = collection(docRef, 'parts')

        if(part.number !== partNumber) {
            const q = query(objectRef, where("number", "==", part.number))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
                if(doc.data() !== undefined) {
                    throw `Part with Part# ${part.number} already exists`
                }
            })
        }

        const partId = await getPartIdByNumber(partNumber)
        const docRef2 = doc(objectRef, partId)

        await updateDoc(docRef2, {
            number: part.number,
            name: part.name,
            price: part.price,
            type: part.type,
            manufacturer: part.manufacturer
        })
    }

    async function updatePartInventory(inventory, partNum) {
        const partId = await getPartIdByNumber(partNum)
        const docRef = doc(db, 'users', currentUser.uid)
        const objectRef = collection(docRef, 'parts')
        const docRef2 = doc(objectRef, partId)
        const colRef = collection(docRef2, 'inventory')

        for (let i in inventory) {
            if(inventory[i].hasOwnProperty('new_inventory_quantity')) {
                const q = query(colRef, where('inventory_location', '==', inventory[i].inventory_location))
                const querySnapshot = await getDocs(q)
                let id = 0
                querySnapshot.forEach((doc) => {
                    id = doc.id
                })
                const docRef3 = doc(colRef, id)
                await updateDoc(docRef3, {
                    inventory_quantity: inventory[i].new_inventory_quantity
                })
            }
        }
    }

    async function createPart(part) {
        let partArr = await getParts()

        const partExists = partArr.find((p) => {
            return p.number === part.number
        })

        if(partExists !== undefined) {
            throw `Part with Part# ${part.number} already exists`
        }

        const docRef = doc(db, 'users', currentUser.uid)
        const colRef = collection(docRef, 'parts')
        return await addDoc(colRef, {
            number: part.number,
            name: part.name,
            price: part.price,
            type: part.type,
            manufacturer: part.manufacturer
        })
    }

    async function deletePart(partNum) {
        const partId = await getPartIdByNumber(partNum)
        const docRef = doc(db, 'users', currentUser.uid)
        const objectRef = collection(docRef, 'parts')
        const docRef2 = doc(objectRef, partId)
        const colRef = collection(docRef2, 'inventory')
        const q  = query(colRef)
        const querySnapshot = await getDocs(q)
        let idArr = []
        querySnapshot.forEach((doc) => {
            idArr.push(doc.id)
        })
        for(let i in idArr) {
            const docRef3 = doc(colRef, idArr[i])
            await deleteDoc(docRef3)
        }
        return await deleteDoc(docRef2)
    }

    async function deleteLocation(location, partNum) {
        const partId = await getPartIdByNumber(partNum)
        const docRef = doc(db, 'users', currentUser.uid)
        const objectRef = collection(docRef, 'parts')
        const docRef2 = doc(objectRef, partId)
        const colRef = collection(docRef2, 'inventory')
        const q = query(colRef, where('inventory_location', '==', location))
        const querySnapshot = await getDocs(q)
        let docId = 0
        querySnapshot.forEach((doc) => {
            docId = doc.id
        })
        const docRef3 = doc(colRef, docId)
        return await deleteDoc(docRef3)
    }
    
    async function createLocationByPart(locationArr, partNumber) {
        const partId = await getPartIdByNumber(partNumber)
        const docRef = doc(db, 'users', currentUser.uid)
        const objectRef = collection(docRef, 'parts')
        const docRef2 = doc(objectRef, partId)
        const colRef = collection(docRef2, 'inventory')
        for(let i = 0; i < locationArr.length; i++) {
            await addDoc(colRef, {
                inventory_location: locationArr[i].location,
                inventory_quantity: locationArr[i].quantity
            })
        }
    }

    async function getPartIdByNumber(partNumber) {
        const docRef = doc(db, 'users', currentUser.uid)
        const colRef = collection(docRef, 'parts')
        const q = query(colRef, where("number", "==", partNumber))
        const querySnapshot = await getDocs(q)
        let partId = 0
        querySnapshot.forEach((doc) => {
            partId = doc.id
        })
        return partId
    }

    async function getPart(partNum) {
        const partId = await getPartIdByNumber(partNum)
        const docRef = doc(db, 'users', currentUser.uid)
        const objectRef = collection(docRef, 'parts')
        const docRef2 = doc(objectRef, partId)
        const docSnap = await getDoc(docRef2)
        if(docSnap.exists()) {
            let inventoryArr = []
            const colRef = collection(docRef2, 'inventory')
            const q = query(colRef)
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
                const inventory = {
                    inventory_location: doc.data().inventory_location,
                    inventory_quantity: doc.data().inventory_quantity
                }
                inventoryArr.push(inventory)
            })
            const part = docSnap.data()
            part['inventory'] = inventoryArr

            return part
        }
    }

    async function getParts() {
        const docRef = doc(db, 'users', currentUser.uid)
        const q  = query(collection(docRef, 'parts'))
        const querySnapshot = await getDocs(q)
        let partArr = []
        for(let i in querySnapshot.docs) {
            const obj = querySnapshot.docs[i].data()
            const objectRef = collection(docRef, 'parts')
            const docRef2 = doc(objectRef, querySnapshot.docs[i].id)
            const colRef = collection(docRef2, 'inventory')
            const q2 = query(colRef)
            const qs = await getDocs(q2)
            let inventoryArr = []
            qs.forEach((doc) => {
                const inventory = {
                    inventory_location: doc.data().inventory_location,
                    inventory_quantity: doc.data().inventory_quantity
                }
                inventoryArr.push(inventory)
            })
            obj['inventory'] = inventoryArr
            partArr.push(obj)
        }
        return partArr
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        getUser,
        createPart,
        createLocationByPart,
        getPart,
        getParts,
        updateUser,
        updatePart,
        updatePartInventory,
        deletePart,
        deleteLocation
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
