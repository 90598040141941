import React, { useState, useEffect, useMemo } from 'react'

function TableRow({ part, screenWidth, onClickRow, setPartData }) {
  const [locations, setLocations] = useState([])

  const totalCount = useMemo(() => {
    let count = 0
    part.inventory.forEach((item) => {
      count = count + item.inventory_quantity
    })
    return count
  }, [part])

  useEffect(() => {
    setLocations(part.inventory)
  }, [part])

  return (
    <tr onClick={() => {
      onClickRow()
      setPartData(part)
    }}>
        {screenWidth > 768 ? <td className="bold-td">{part.number}</td> : <>
          <td className="mobile-view-part-heading">
            <div className="part-number">{part.number}</div>
            <div className="part-name">{part.name}</div>
          </td>
        </>}
        {screenWidth > 768 && <>
          <td className="og-td">{part.name}</td>
          <td className="price-td">
              {part.price != null && <>${part.price}</>}
          </td>
        </>}
        {screenWidth > 1199 && <td className="og-td">{part.type}</td>}
        {screenWidth > 768 && <td className="og-td">{part.manufacturer}</td>}
        {screenWidth > 1199 &&
          <td className="og-td location-td">
            {locations.length && <div>
              {locations.length > 1 ? <>
                {locations.map((location) => {
                  return <div key={location.inventory_location}><div>{location.inventory_quantity} in {location.inventory_location}</div></div>
                })}
              </> : <>
              {locations.map((location) => {
                  return <div key={location.inventory_location}><div>{location.inventory_location}</div></div>
                })}
              </>}
            </div>}
          </td>
        }
        <td className="og-td mobile-align-right">{totalCount}</td>
    </tr>
  )
}

export default TableRow
