import React, { useEffect, useRef } from 'react'
import '../scss/toast.scss'

function Toast({ toast, deleteToast }) {

  const toastRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      toastRef.current.classList.add('show')
    }, 100)

    const interval = setInterval(() => {
      toastRef.current.classList.remove('show')
    }, 4500)

    return () => {
        clearInterval(interval)
    }
  }, [])

  return (
    <li className={`toast-container ${toast.type}`} ref={toastRef} >
      <span>{toast.message}</span>
      <button onClick={() => deleteToast(toast.id)}>
        Close
      </button>
    </li>
  )
}

export default Toast