import React, { useState, useEffect } from 'react'
import Hamburger from './Icons/Hamburger'
import DashboardIcon from './Icons/DashboardIcon'
import AccountIcon from './Icons/AccountIcon'
import '../scss/sidenav.scss'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import useClickOutside from '../hooks/useClickOutside'

function SideNav() {
    const [navOpen, setNavOpen] = useState(false)
    const [name, setName] = useState('')

    const { currentUser, getUser } = useAuth()

    useEffect(() => {
        const userData = getUser(currentUser.uid).then((data) => {
            setName(data.name)
        })
    }, [currentUser.uid, getUser])

    function handleNavigation() {
        console.log('handleNavigation')
        setNavOpen((prevOpen) => !prevOpen)
        if(!navOpen) {
            domNode.current.classList.add('nav-open')
        } else {
            domNode.current.classList.remove('nav-open')
        }
    }

    let domNode = useClickOutside(() => {
        setNavOpen(false)
        domNode.current.classList.remove('nav-open')
    })

    return (
        <div className="aurora-container-left" ref={domNode}>
            <div className="top-menu-wrapper">
                <Hamburger onClick={handleNavigation} />
                    <Link to='/dashboard' className="menu-link">
                        <DashboardIcon />
                        <span>Dashboard</span>
                    </Link>
            </div>
            <div className="bottom-menu-wrapper">
                <AccountIcon />
                <div className="user-info">
                    <p className="user__name">{name}</p>
                    <p className="user__email">{currentUser.email}</p>
                </div>
            </div>
        </div>
    )
}

export default SideNav