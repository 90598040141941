import React, { useState, useRef, useEffect } from 'react';
import '../scss/signup.scss';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext'
import Loader from '../components/Loader'
import Navigation from '../components/AuthenticationNavigation'

function Signup() {
  const emailRef = useRef()
  const nameRef = useRef()
  const passwordRef = useRef()

  const { signup } = useAuth()

  const [error, setError] = useState('')
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [nameValid, setNameValid] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [user, setUser] = useState({
    email: null,
    name: null
  })

  const navigate = useNavigate()

  function handleResize() {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [screenWidth])

  function validateName() {
    if(nameRef.current.value === null || nameRef.current.value === '') {
      setNameError('Name is required')
      document.getElementById('name').classList.add('error-input-field')
    } else {
      setNameValid(true)
      setNameError('')
      setUser(prevValues => ({
        ...prevValues,
        name: nameRef.current.value
      }))
      document.getElementById('name').classList.remove('error-input-field')
    }
  }

  function validateEmail() {
    const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

    if(emailRef.current.value === null || emailRef.current.value === '') {
      setEmailError('Email Address is required')
      document.getElementById('email').classList.add('error-input-field')
    } else if(!re.test(emailRef.current.value)) {
      setEmailError('Email Address must be valid')
      document.getElementById('email').classList.add('error-input-field')
    } else {
      setEmailValid(true)
      setEmailError('')
      setUser(prevValues => ({
        ...prevValues,
        email: emailRef.current.value
      }))
      document.getElementById('email').classList.remove('error-input-field')
    }
  }

  function validatePassword() {
    const upperCaseRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/
    const specialCharacterRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/

    if(passwordRef.current.value === null || passwordRef.current.value === '') {
      setPasswordError('Password is required')
      document.getElementById('password').classList.add('error-input-field')
    } else if(passwordRef.current.value.length < 8) {
      setPasswordError('Password must be at least 8 characters long')
      document.getElementById('password').classList.add('error-input-field')
    } else if(!upperCaseRe.test(passwordRef.current.value)) {
      setPasswordError('Password must containt at least 1 uppercase character')
      document.getElementById('password').classList.add('error-input-field')
    } else if(!specialCharacterRe.test(passwordRef.current.value)) {
      setPasswordError('Password must contain at least 1 special character')
      document.getElementById('password').classList.add('error-input-field')
    } else {
      setPasswordValid(true)
      setPasswordError('')
      document.getElementById('password').classList.remove('error-input-field')
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if(nameValid && emailValid && passwordValid) {
      try {
        setError('')
        setLoading(true)
        await signup(emailRef.current.value, passwordRef.current.value, user)
        navigate('/dashboard')
      } catch {
        setEmailError('Sorry, that email address is already associated with an account.')
        document.getElementById('email').classList.add('error-input-field')
      }
      setLoading(false)
    } else {
      validateName()
      validateEmail()
      validatePassword()
    }
  }

  return (
    <div className='signup-page'>
      {screenWidth > 768 &&
        <Navigation cta='Joined us before?' link='/login' linkText='Login' />
      }
      {screenWidth < 768 && <>
        <div className="seaFloorPlantLeft"></div>
        <div className="seaFloorPlantRight"></div>
      </>}
      <div className="form-card">
        <h2 className="card-heading">Sign Up with Email</h2>
        {error && <div className="error-card">{error}</div>}
        <form className="authentication-form" onSubmit={handleSubmit}>
          <label htmlFor="name" className="form-input-label">full name</label>
          <input type="text" id="name" placeholder="Enter a name" className="form-input-field" ref={nameRef} onBlur={validateName} />
          {nameError && <p className="error-message">{nameError}</p>}
          <label htmlFor="email" className="form-input-label">email address</label>
          <input type="text" id="email" placeholder="Enter your email address" className="form-input-field" ref={emailRef} onBlur={validateEmail} />
          {emailError && <p className="error-message">{emailError}</p>}
          <label htmlFor="password" className="form-input-label">password</label>
          <input type="password" id="password" placeholder="Create a password" className="form-input-field password-input-field" ref={passwordRef} onBlur={validatePassword} />
          {passwordError && <p className="error-message">{passwordError}</p>}
          <button type="submit" className="submit-form-btn green">Sign Up</button>
        </form>
        {loading && <div className="loading-message">
          <Loader /><p>Creating account</p>
        </div>}
      </div>
      {screenWidth < 768 &&
        <>
          <span className="login-cta">Joined us before? <Link to='/login'>Login</Link></span>
        </>
      }
    </div>
  )
}

export default Signup